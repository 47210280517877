import { FC, ReactNode } from 'react';

import { SwipableBox } from '@custom-components/SwipableBox';
import { useLinkComponent } from '@link';
import { BreadCrumbsRendering } from '@sitecore/types/manual/BreadCrumbs';
import { Box, Divider, NavLink, PageGrid, Stack, Text } from '@sparky';
import { useMediaQuery } from '@sparky/hooks';
import { ChevronRightIcon, HomeIcon } from '@sparky/icons';

const ConditionalWrapper: FC<{ children: ReactNode; shouldWrap: boolean }> = ({ shouldWrap, children }) => {
  return shouldWrap ? (
    <PageGrid>
      <PageGrid.Item gridColumn="1/-1">
        <Divider />
        {children}
      </PageGrid.Item>
    </PageGrid>
  ) : (
    <>{children}</>
  );
};

const BreadCrumbs: FC<BreadCrumbsRendering> = ({ fields }) => {
  const Link = useLinkComponent();

  const isDesktop = useMediaQuery('lg');
  const paddingX = isDesktop ? '0' : '3';

  if (!fields.breadCrumbs?.length) return null;
  return (
    <ConditionalWrapper shouldWrap={isDesktop}>
      <SwipableBox as="nav" paddingY="3" paddingLeft={paddingX}>
        <Stack direction="row" alignY="center" as="ul" gap="1">
          {fields.breadCrumbs.map((breadCrumb, i) => (
            <Text as="li" key={i} whiteSpace="nowrap">
              <Stack direction="row" gap="1">
                {i === fields.breadCrumbs.length - 1 ? (
                  <Box paddingRight={paddingX}>
                    <Text size="BodyS" color="textLowEmphasis">
                      {breadCrumb.name}
                    </Text>
                  </Box>
                ) : (
                  <>
                    <Link href={breadCrumb.url} linkType={breadCrumb.linktype} currentMatch="exact">
                      <NavLink variant="tertiary" leftIcon={i === 0 ? <HomeIcon /> : undefined}>
                        {breadCrumb.name}
                      </NavLink>
                    </Link>
                    <ChevronRightIcon color="iconSecondary" />
                  </>
                )}
              </Stack>
            </Text>
          ))}
        </Stack>
      </SwipableBox>
    </ConditionalWrapper>
  );
};

export default BreadCrumbs;
