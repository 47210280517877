import { styled } from '@sparky/stitches';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { StyledBox } from 'libs/sparky/src/components/Box/Box';

/* This Box can be scrolled horizontally but it does not show the scrollbar */
export const SwipableBox = styled(StyledBox, {
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
});
